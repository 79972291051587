import React from 'react';
import {
    Background,
    Content,
    Header,
    HeaderTitle,
    Image,
    MainText,
    TextWrapper,
    Tile,
    TileText,
    TileTextWrapper,
    TileTitle,
    TileWrapper,
    Wrapper
} from './styledCultivation';
import { cultivationData } from '../../core/arrays';
import bg1 from '../../common/Images/backgroundImages/PP_wektor_05.svg';
import bg2 from '../../common/Images/backgroundImages/circle.svg';
import bg3 from '../../common/Images/backgroundImages/PP_wektor_07.svg';
import bg4 from '../../common/Images/backgroundImages/PP_wektor_09.svg';
import { motion } from 'framer-motion';

export const Cultivation = () => {

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Uprawa paulowni</HeaderTitle>
            </Header>
            <Content>
                <Background src={bg1} alt='background' />
                <Background src={bg2} right alt='background' />
                <Background src={bg3} left alt='background' />
                <Background src={bg4} bottom alt='background' />

                <TextWrapper>
                    <MainText>
                        Chcemy się z Państwem podzielić naszym doświadczeniem w poniższych dziedzinach:
                    </MainText>
                </TextWrapper>

                <TileWrapper>
                    {cultivationData.map((item) => (
                        <Tile
                            key={item.id}
                            as={motion.div}
                            initial={{ x: '20%', opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.7 }}
                            left={item.id % 2 === 0}>
                            {item.id % 2 === 0 ? (
                                <>
                                    <Image src={item.image} alt='Tile' />
                                    <TileTextWrapper>
                                        <TileTitle>{item.title}</TileTitle>
                                        <TileText>{item.text}</TileText>
                                    </TileTextWrapper>
                                </>
                            ) : (
                                <>
                                    <TileTextWrapper>
                                        <TileTitle>{item.title}</TileTitle>
                                        <TileText>{item.text}</TileText>
                                    </TileTextWrapper>
                                    <Image src={item.image} examination={item.id === 9} alt='Wrapper' />
                                </>
                            )}
                        </Tile>
                    ))}

                </TileWrapper>
            </Content>

        </Wrapper >
    );
};

