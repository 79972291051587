import React from 'react';
import { Content, Header, HeaderTitle, Image, TD, THead, TH, Table, Tile, TileText, TileTextWrapper, TileTitle, TileWrapper, Wrapper, TBody, TablesWrapper, TableWrapper, Caption, Title, CalcLink, PriceWrapper, Background, Information, InformationImg, InformationWrapper } from './styledOffer';
import seedling from '../../common/Images/OfferImages/seadling.png';
import tree from '../../common/Images/OfferImages/tree.png';
import { Order } from '../../common/Order/order';
import { seedlingQuantityPrices, treeHeightPrices } from '../../core/arrays';
import bg1 from '../../common/Images/backgroundImages/PP_wektor_02.svg';
import bg2 from '../../common/Images/backgroundImages/circle.svg';
import bg3 from '../../common/Images/backgroundImages/PP_wektor_06.svg';
import information from '../../common/Images/OfferImages/information.svg';
import { motion } from 'framer-motion';

export const Offer = () => {

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Nasza oferta</HeaderTitle>
            </Header>
            <Background src={bg1} alt='background' />
            <Background src={bg2} right alt='background' />
            <Background src={bg3} left alt='background' />

            <Content>
                <TileWrapper>
                    <Tile
                        as={motion.div}
                        initial={{ x: '-20%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <TileTextWrapper>
                            <TileTitle>
                                Sadzonki
                            </TileTitle>
                            <TileText>
                                Nasza oferta zawiera sadzonki Paulowni, które są dostępne w różnych ilościach, idealne dla tych, którzy pragną wspierać rozwój polskiej produkcji drzew. Dostępne odmiany to Shang Tong i Tomentosa, które nie tylko zapewniają szybki wzrost, ale również są bardziej odporne na warunki atmosferyczne. To doskonały wybór dla tych, którzy kierują się troską o środowisko i marzą o dodaniu zieleni do swojego otoczenia. Nasze sadzonki to nie tylko inwestycja finansowa, ale także inwestycja w przyszłość - poprawa jakości powietrza wokół nas, co ma istotne znaczenie dla naszego środowiska. Zdecyduj się na zakup naszych sadzonek Paulowni, aby wesprzeć lokalną produkcję i cieszyć się korzyściami ekologicznymi, jakie przynosi ta piękna roślina. Warto zaznaczyć, że sadzonki wysiewamy sami w polskich warunkach z nasion, które pochodzą z drzewa rosnącego w Polsce. Dzięki temu możemy zagwarantować, że nasze drzewa są odpowiednio przystosowane do lokalnego klimatu, co sprzyja ich zdrowemu wzrostowi i rozwojowi.
                            </TileText>
                        </TileTextWrapper>
                        <Image src={seedling} alt='tile' />
                    </Tile>

                    <Tile left
                        as={motion.div}
                        initial={{ x: '20%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <Image treeSize src={tree} alt='tile' />
                        <TileTextWrapper>
                            <TileTitle>
                                Drzewa
                            </TileTitle>
                            <TileText>
                                Nasze drzewa Paulowni są dostępne w różnych wysokościach od 1,5 m do 4,5 m i stanowią doskonałą inwestycję w przyszłość oraz ochronę środowiska. Odmiana Shang Tong jest łatwa w uprawie, a główną korzyścią płynącą z zakupu drzewa jest szybsze uzyskanie zysków w porównaniu do sadzonki. Wybierając drzewa Paulowni, podejmujesz decyzję na rzecz przyszłości i dobra naszej planety. Warto dodać, że nasze drzewa są przez nas sadzone jako sadzonki, a następnie rosną w naszym klimacie przez okres dwóch lat. Ten proces pozwala nam zagwarantować, że drzewa są solidnie ukorzenione i przygotowane do szybkiego wzrostu, gdy zostaną przeniesione do ostatecznej lokalizacji.
                            </TileText>
                        </TileTextWrapper>
                    </Tile>

                    <PriceWrapper
                        as={motion.header}
                        initial={{ x: '-20%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.7 }}
                    >
                        <Title>Cennik</Title>
                        <TablesWrapper>
                            <TableWrapper>
                                <Caption>Cena drzewa według wysokości</Caption>
                                <Table>
                                    <THead>
                                        <tr>
                                            <TH>Od</TH>
                                            <TH>Do</TH>
                                            <TH>Cena za sztukę</TH>
                                        </tr>
                                    </THead>
                                    <TBody>
                                        {treeHeightPrices.map((price, index) => (
                                            <tr key={index}>
                                                <TD>{price.from}</TD>
                                                <TD>{price.to}</TD>
                                                <TD>{price.price}</TD>
                                            </tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </TableWrapper>

                            <TableWrapper>
                                <Caption>Cena sadzonki według liczby</Caption>
                                <Table>
                                    <THead>
                                        <tr>
                                            <TH>Od</TH>
                                            <TH>Do</TH>
                                            <TH>Cena za sztukę</TH>
                                        </tr>
                                    </THead>
                                    <TBody>
                                        {seedlingQuantityPrices.map((price, index) => (
                                            <tr key={index}>
                                                <TD>{price.from}</TD>
                                                <TD>{price.to}</TD>
                                                <TD>{price.price}</TD>
                                            </tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </TableWrapper>
                            <InformationWrapper>
                                <InformationImg src={information} alt='information' />
                                <Information> Sprzedaż drzewek prowadzimy od listopada do marca </Information>
                            </InformationWrapper>

                        </TablesWrapper>
                        <CalcLink to="/Kalkulator" onClick={() => window.scrollTo(0, 0)} >Zaplanuj swoją plantacje</CalcLink>
                    </PriceWrapper>
                </TileWrapper>
            </Content>
            <Order />
        </Wrapper >
    );
};
