import React from 'react';
import { AboutImage, AboutText, AboutTextWrapper, AboutTitle, Background, ContentWrapper, Wrapper } from './styledAbout';
import firstImg from '../../../../common/Images/HomeImages/about1.jpg'
import scndImg from '../../../../common/Images/HomeImages/about2.jpg'
import background1 from '../../../../common/Images/backgroundImages/circle2.svg';
import background2 from '../../../../common/Images/backgroundImages/leaf2.svg';
import { motion } from 'framer-motion';

export const About = () => {
    return (
        <Wrapper>
            <ContentWrapper>
                <AboutTextWrapper>
                    <Background src={background1} alt='background' />
                    <AboutTitle>Paulownia: Nasza Pasja, Twoja Zielona Przyszłość</AboutTitle>
                    <AboutText> Witaj na naszej stronie! Jesteśmy dumni, że możemy dostarczyć Ci najwyższej jakości sadzonki i drzewa Paulownia, które nie tylko ozdobią Twój ogród, ale znajdą zastosowanie na plantacjach drzew użytkowych. Nasze starannie wyselekcjonowane sadzonki są w zasięgu Twojej ręki! Gwarantujemy Ci profesjonalizm i pełne zaangażowanie w to, co robimy. Paulownie to nasza pasja.
                    </AboutText>
                </AboutTextWrapper>
                <AboutImage src={firstImg} alt='background' />
            </ContentWrapper>

            <ContentWrapper reverse>
                <Background src={background2} alt='background' right />
                <AboutImage
                    initial={{ x: '-30%', opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.7 }}
                    as={motion.img}
                    src={scndImg} alt='background' />
                <AboutTextWrapper
                    as={motion.div}
                    initial={{ x: '30%', opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.7 }}
                >
                    <AboutText> Nasza firma opiera się na dziesięcioletnim doświadczeniu, które zdobyliśmy dzięki nieustannym próbom i błędom oraz podróżom po świecie, gdzie Paulownia występuje w różnorodnych klimatach i warunkach. To właśnie te doświadczenia sprawiły, że jesteśmy ekspertami w hodowli tych drzew. Naszą pasją jest dostarczanie Ci najlepszych sadzonek, aby Twoje ogrody i plantacje drzew mogły kwitnąć i rosnąć tak, jak nasze drzewa. Razem możemy kroczyć w kierunku zielonej przyszłości!
                    </AboutText>
                </AboutTextWrapper>
            </ContentWrapper>

        </Wrapper>
    );
};

