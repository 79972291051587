import styled from "styled-components";

export const Wrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
    padding: 40px;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        padding: 20px;
    };
`;

export const Frame = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    @media (min-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        &:hover {
            filter: drop-shadow(0 0 12px ${({ theme }) => theme.color.secondColor});        
        };    
    };


`;

export const Image = styled.img`
    border-radius: 10px;
    border: 4px solid ${({ theme }) => theme.color.secondColor};
    max-width: 1200px;
    width: 100%;
`;

export const Play = styled.img`
    position: absolute;
    max-width: 180px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        max-width: 120px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        max-width: 60px;
    };
`; 