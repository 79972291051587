import styled from "styled-components";

export const Wrapper = styled.div`
    min-height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
    padding: 10px 0;
    overflow-x: hidden;
`;

export const Title = styled.header`
    font-size: 48px;
    margin-top: 40px;
    font-weight: 600 ;
    text-align: center;
    color: ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 28px;
    };  
`;