import React from 'react';
import { Background, ContactButton, ContactItemWrapper, ContactLink, ContactSvgWrapper, ContactTitle, ContactWrapper, SVGIcon, SVGLink, Span, Wrapper } from './styledContact';
import facebook from '../../../../common/Images/SVG/facebook.svg'
import phone from '../../../../common/Images/SVG/phone.svg'
import mail from '../../../../common/Images/SVG/mail.svg'
import bg from '../../../../common/Images/backgroundImages/PP_wektor_03.svg';
import { motion } from 'framer-motion';

export const Contact = () => {
    return (
        <Wrapper>
            <Background src={bg} alt='background' />
            <ContactWrapper
                as={motion.div}
                initial={{ y: '30%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
            >
                <ContactItemWrapper>
                    <ContactLink to="/Polskie-Paulownie-Kontakt" onClick={() => window.scrollTo(0, 0)}>
                        <ContactButton>Wypełnij formularz kontaktowy</ContactButton>
                    </ContactLink>
                </ContactItemWrapper>

                <Span>lub</Span>

                <ContactItemWrapper>
                    <ContactTitle>Skontaktuj się z nami</ContactTitle>
                    <ContactSvgWrapper>
                        <SVGLink href='https://www.facebook.com/profile.php?id=100067548942538' target='blank' >
                            <SVGIcon src={facebook} alt='facebook' />
                        </SVGLink>
                        <SVGLink href="mailto: polskie.paulownie@gmail.com">
                            <SVGIcon src={mail} alt='e-mail' />
                        </SVGLink>
                        <SVGLink href="tel:603554885">
                            <SVGIcon src={phone} alt='phone' />
                        </SVGLink>
                    </ContactSvgWrapper>
                </ContactItemWrapper>
            </ContactWrapper>
        </Wrapper >
    );
};
