import React from 'react';
import { Background, ContentWrapper, ServicesImage, ServicesText, ServicesTextWrapper, ServicesTitle, Wrapper } from './styledServices';
import firstImg from '../../../../common/Images/HomeImages/services1.jpg';
import scndImg from '../../../../common/Images/HomeImages/services2.jpg';
import background1 from '../../../../common/Images/backgroundImages/circle2.svg';
import { motion } from 'framer-motion';

export const Services = () => {
    return (
        <Wrapper>
            <Background src={background1} alt='background' />
            <ContentWrapper>
                <ServicesImage src={firstImg} alt='background'
                    initial={{ x: '-30%', opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: false }}
                    transition={{ duration: 0.7 }}
                    as={motion.img}
                />

                <ServicesTextWrapper
                    as={motion.div}
                    initial={{ x: '30%', opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: false }}
                    transition={{ duration: 0.7 }}
                >
                    <ServicesTitle>Sadzonki</ServicesTitle>
                    <ServicesText>Oferujemy sadzonki Paulowni w różnych ilościach, idealne dla tych, którzy chcą wesprzeć polską produkcję drzew. Dostępne odmiany to Shang Tong i Tomentosa, zapewniające szybki wzrost i produkcję tlenu. To doskonały wybór dla tych, którzy dbają o środowisko i chcą dodać zieleni do swojego otoczenia. Nasze sadzonki są wysiewane w polskich warunkach, z nasion pochodzących z drzewa rosnącego w Polsce, co gwarantuje, że są one odpowiednio przystosowane do lokalnego klimatu i terenu. Dzięki temu możemy zapewnić, że nasze drzewa rosną silnie i zdrowo, przyczyniając się do zielonej przestrzeni w całej Polsce.
                    </ServicesText>
                </ServicesTextWrapper>

            </ContentWrapper>

            <ContentWrapper reverse>
                    <ServicesTextWrapper
                        as={motion.div}
                        initial={{ x: '-30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ duration: 0.7 }}
                    >
                        <ServicesTitle>Drzewa</ServicesTitle>
                        <ServicesText>Nasze drzewa Paulowni, oferowane w zakresie wysokości od 1,5 m do 4,5 m, stanowią doskonałą inwestycję w przyszłość. Odmiana Shang Tong, charakteryzująca się łatwością w uprawie i naturalnym pięknem, pozwala dodać zieleni do otoczenia, jednocześnie przyczyniając się do poprawy jakości powietrza i ochrony środowiska. Warto podkreślić, że nasze drzewa przechodzą proces sadzenia w postaci młodych roślin, które następnie przez dwa lata rosną w polskim klimacie.
                            Dzięki temu zapewniamy, że roślina jest dobrze ukorzeniona, co powoduje prawidłowy wzrost w nowej lokalizacji.</ServicesText>
                    </ServicesTextWrapper>

                    <ServicesImage tree src={scndImg} alt='background'
                        initial={{ x: '30%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ duration: 0.7 }}
                        as={motion.img}
                    />
            </ContentWrapper>
        </Wrapper>
    );
};

