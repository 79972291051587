import styled from "styled-components";
import bg from '../../../../common/Images/GalleryImages/Images/GALERIA_005.jpg';

export const Wrapper = styled.section`
    min-height: 100vh;
    padding: 40px;
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
`;

export const Form = styled.form`
    box-shadow: 0 0 10px;
    border-radius: 10px;
    padding: 40px;
    display: grid;
    max-width: 800px;
    margin: 0 auto;
    gap: 28px;
    background: ${({ theme }) => theme.color.mainColor};
    box-shadow: 0 0 10px white;
`;

export const Input = styled.input`
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.color.thirdColor};
    background: none;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

export const Label = styled.label`
    display: grid;
    gap: 10px;
`;

export const Button = styled.button`
    margin: 0 auto;
    background: ${({ theme }) => theme.color.secondColor};
    color: white;
    padding: 12px 80px;
    border: none;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.color.hoverSecondColor};
    }
`;