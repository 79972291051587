import React from 'react';
import { Background, BlogWrapper, Content, ContentWrapper, Header, HeaderTitle, LinksWrapper, NavLink, Navigation, ReadPost, SectionTitle, TextWrapper, Tile, TileAuthor, TileDescription, TileImage, TileImageWrapper, TileTitle, TilesWrapper, Title, Wrapper } from './styledBlog';
import bg from '../../common/Images/backgroundImages/PP_wektor_01.svg';
import bg2 from '../../common/Images/backgroundImages/PP_wektor_05.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchPosts } from '../../core/fetchPosts';
import ReactHtmlParser from 'react-html-parser';
import { Loader } from '../../common/Loader/loader';
import { Error } from '../../common/Error/error';


export const Blog = () => {

    const { data, isLoading, isError } = useQuery({
        queryKey: ['posts'],
        queryFn: fetchPosts
    });

    console.log(data);


    return (
        <Wrapper>
            {isLoading ? <Loader /> : isError ? <Error /> : (
                <>
                    <Header>
                        <HeaderTitle>Blog</HeaderTitle>
                    </Header>
                    <Background src={bg} alt='background' />
                    <Background right src={bg2} alt='second background' />
                    <BlogWrapper>
                        <Title>Dowiedz się wiecęj o Paulowniach czytając naszego bloga</Title>

                        <ContentWrapper>
                            <Content>
                                <SectionTitle>Najnowsze artykuły:</SectionTitle>

                                <TilesWrapper>
                                    {data?.map((article, index) => (
                                        <Tile
                                            to={`/Blog/${article.id}`}
                                            reversed={index % 2 === 0}
                                            key={index}
                                            onClick={() => window.scrollTo(0, 0)}
                                        >
                                            <TileImageWrapper>
                                                <TileImage src={article.img} alt='background' />
                                                <ReadPost>Czytaj wpis</ReadPost>
                                            </TileImageWrapper>
                                            <TextWrapper>
                                                <TileTitle>{article.title}</TileTitle>
                                                <TileDescription>{ReactHtmlParser(article.description)}</TileDescription>
                                                <TileAuthor>Napisano przez:  Maciej Jedrusik</TileAuthor>
                                            </TextWrapper>
                                        </Tile>
                                    ))}
                                </TilesWrapper>
                            </Content>



                            <div style={{ padding: 40 }}>
                                <Navigation>
                                    <SectionTitle>Nasze wpisy:</SectionTitle>
                                    <LinksWrapper>
                                        {data.map((article) => (
                                            <NavLink key={article.id} to={article.id} onClick={() => window.scrollTo(0, 0)} >{article.title} </NavLink>
                                        ))}
                                    </LinksWrapper>
                                </Navigation>
                            </div>
                        </ContentWrapper>
                    </BlogWrapper>
                </>
            )}
        </Wrapper>
    );
};

