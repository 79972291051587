import React from 'react';
import { Title, Wrapper } from './styledHome';
import { Header } from './Sections/Header/header';
import { About } from './Sections/About/about';
import { Services } from './Sections/Services/services';
import { Gallery } from './Sections/Gallery/gallery';
import { Contact } from './Sections/Contact/contact';
import { VideoSection } from './Sections/Video/videoSection';
import { motion } from 'framer-motion';
import { DdTvn } from './Sections/DDTVN/ddTvn';

export const Home = () => {

    return (
        <Wrapper>
            <Header />
            <About />
            <VideoSection />
            <Title
                as={motion.header}
                initial={{ y: '-30%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
            >Nasza oferta obejmuje:
            </Title>
            <Services />
            <Title
                as={motion.header}
                initial={{ y: '-30%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
            >Sprawdź nasz reportaż w Dzień Dobry TVN
            </Title>
            <DdTvn />
            <Title
                as={motion.header}
                initial={{ y: '-30%', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7 }}
            >Galeria zdjęć:</Title>

            <Gallery />
            <Contact />
        </Wrapper>
    );
};

