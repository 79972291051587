import { Link } from "react-router-dom";
import styled from "styled-components";
import bg from '../../common/Images/backgroundImages/bg.svg';

export const Wrapper = styled.div`
    position: fixed;
    bottom: 60px;
    right: 60px;
    z-index: 999;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        right: 0px;
        bottom: 0px;
    };
`;

export const SmallDropBox = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.color.hoverSecondColor};
    padding: 16px;
    box-shadow: 0 0 10px ${({ theme }) => theme.color.thirdColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 2px double white;

    &:hover {
        opacity: 0.8;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        right: 30px;
        position: absolute;
        bottom: 30px;
    };
`;

export const Image = styled.img`
    max-width: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        max-width: 30px;
    };
`;


export const Container = styled.div`
    background: ${({ theme }) => theme.color.secondColor};
    color: white;
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
    padding: 80px;
    display:grid;
    border: 2px  solid #77A574;
    box-shadow: 0 0 10px ${({ theme }) => theme.color.thirdColor};
    border-radius: 54px 50px 0px 50px;
    gap: 20px;
    min-height: 240px;
    position: relative;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        padding: 40px;
        border-radius: 0;
        width: 100%;
        min-height: 0px;
    };
`;

export const Logo = styled.img`
    position: absolute;
    max-width: 96px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        display: none;
    };
`;

export const Span = styled.span`
    font-size: 32px;
    text-align: center;
    letter-spacing: 1px;
    border-bottom: 2px solid white;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size:18px;
    };
`;

export const LinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        flex-direction: column;
        gap: 10px;
    };
`;

export const DropLink = styled(Link)`
    background: #60935C;
    color: white;
    border: 2px solid white;
    text-decoration: none;
    font-size: 20px;
    padding: 12px 40px;
    border-radius: 10px;
    transition: 0.3s;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 16px;
        margin: 0 auto;
        padding: 10px 20px;
        min-width: 160px;
        text-align: center;
    };

    &:hover {
        opacity: 0.8;
    };
`;

export const CloseButton = styled.button`
    position: absolute;
    font-size: 18px;
    right: 20px;
    top: 20px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    border-radius: 50%;

    &:hover {
        transform: scale(1.3);
    };
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        top: 10px;
        
    };
`;