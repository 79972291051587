import { Link } from "react-router-dom";
import styled from "styled-components";
import { buttonsAnimation, opacitySlide, slideBottom, slideRight, slideTop } from "../../../../core/animations";
import Slider from "react-slick";

export const Wrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 40px;
    padding: 40px;
    box-shadow: 0px 20px 48px -26px rgba(12, 106, 0, 0.30);
    animation: ${opacitySlide} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        grid-template-columns: 1fr;
        padding: 0px;
    };
`;

export const Background = styled.img <{ right?: boolean, }>`
    position: absolute;
    top: 40px;
    left: 140px;
    opacity: 0.17;
    max-width: 600px;
    z-index: -1;
`;

export const HeaderTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        gap: 12px;
    };
`;

export const HeaderLinksSpan = styled.p`
    font-size: 24px;
    min-width: 40px;
    text-align: center;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 16px;
    };
`;

export const HeaderLinksWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    animation: ${slideBottom} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        margin: 0 auto;
        gap: 32px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        flex-direction: column;
        gap: 12px;
    };
`;

export const HeaderTitle = styled.h1`
    font-size: 54px;
    text-align: center;
    color: #4D534D;
    animation: ${slideTop} 1s;
    margin: 12px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 36px;
        margin: 10px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 36px;
    };
`;

export const HeaderLink = styled(Link)`
    max-width: 200px;
    margin: 0 auto;
    width: 100%;
    animation: ${buttonsAnimation} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        max-width: 160px;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.firstBreakPoint}px){
        width: 0;
    };
`;

export const HeaderButton = styled(Link)`
    padding: 16px 24px;
    background: ${({ theme }) => theme.color.secondColor};
    color: white;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    text-decoration: none;
    font-weight: bold;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    max-width: 220px;

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        font-size: 14px;
        padding: 12px;
    };

    &:hover {
        background: ${({ theme }) => theme.color.hoverSecondColor};
        box-shadow: 0 0 6px ${({ theme }) => theme.color.secondColor};

    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        transform: none;
    };
    }
`;

export const CustomSlider = styled(Slider)`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        max-width: 500px;
    };
    @media (max-width: ${({ theme }) => theme.breakPoint.mobileMax}px){
        max-width: 320px;
    };
`;

export const HeaderImage = styled.img`
    animation: ${slideRight} 1s;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        animation: ${slideBottom} 1s;
    };
`;