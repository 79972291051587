import React from 'react';
import { Frame, Image, Play, Wrapper } from './styledDdTvn';
import bg from '../../../../common/Images/DDTvn/BG.png';
import playButton from '../../../../common/Images/DDTvn/playBUtton.svg';

export const DdTvn = () => {
    return (
        <Wrapper>
            <Frame href='https://dziendobry.tvn.pl/dom/ogrod/ktore-rosliny-wytwarzaja-najwiecej-tleny-porady-ogrodnika-st8129004' target='blank'>
                <Image src={bg} alt='DDtvn' />
                <Play src={playButton} alt='play' />
            </Frame>
        </Wrapper>
    );
};

